import React from 'react';
import styled from 'styled-components';

import Container from 'components/Container';
import Form from 'components/Form/Form';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

const Contact = props => {
  return (
    <Root id="section-contact">
      <Container>
        <DropShapeUpWrapper>
          <h1>
            As a homebuilder, we believe that a home is bigger than the walls
            that define it.
          </h1>
          <img
            src={require('src/assets/images/drop_shape_small.png')}
            alt="drop_shape_small"
          />
        </DropShapeUpWrapper>
        <DropShapeDownWrapper
          src={require('src/assets/images/drop_shape_big.png')}
          alt="drop_shape_big"
        />
        <FormWrapper>
          <Form />
        </FormWrapper>
      </Container>
    </Root>
  );
};

const Root = styled.div`
  position: relative;
  margin-bottom: ${vw(80)};
  @media (min-width: ${media.tablet}) {
    margin-bottom: ${vwTablet(175)};
  }
  @media (min-width: ${media.desktop}) {
    margin-bottom: ${vwDesktop(120)};
  }
`;

const DropShapeUpWrapper = styled.div`
  position: relative;
  z-index: 2;
  padding-top: ${vw(31)};
  padding-bottom: ${vw(62)};
  padding-left: ${vw(25)};
  padding-right: ${vw(25)};
  text-align: left;
  h1 {
    font-size: ${vw(16)};
    line-height: ${vw(24)};
    letter-spacing: ${vw(1)};
    color: #ffffff;
    text-transform: uppercase;
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
  }
  img {
    position: absolute;
    top: 0;
    right: 0;
    width: 75%;
    height: auto;
  }
  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(63)};
    padding-bottom: ${vwTablet(129)};
    padding-left: 0;
    padding-right: 0;
    h1 {
      font-size: ${vwTablet(24)};
      line-height: ${vwTablet(50)};
      letter-spacing: ${vwTablet(1.6)};
      margin-left: 25%;
      width: 64%;
      text-align: left;
    }
    img {
      width: 58.33%;
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(31)};
    padding-bottom: ${vwDesktop(62)};
    h1 {
      font-size: ${vwDesktop(28)};
      line-height: ${vwDesktop(50)};
      letter-spacing: ${vwDesktop(1.87)};
      margin-left: 41.67%;
      width: 41.67%;
      text-align: left;
    }
    img {
      width: 33.33%;
    }
  }
`;

const DropShapeDownWrapper = styled.img`
  position: absolute;
  left: -55%;
  bottom: -4%;
  height: ${vw(342)};
  @media (min-width: ${media.tablet}) {
    left: -43%;
    bottom: -7%;
    height: ${vwTablet(626)};
  }
  @media (min-width: ${media.desktop}) {
    width: 43%;
    height: auto;
    left: ${vwDesktop(80)};
    bottom: -4%;
  }
`;

const FormWrapper = styled.div`
  position: relative;
  z-index: 1;
  @media (min-width: ${media.tablet}) {
    margin-left: 16.67%;
  }
  @media (min-width: ${media.desktop}) {
    margin-left: 41.67%;
    width: 50%;
  }
`;

export default Contact;
