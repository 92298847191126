import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

const Input = props => {
  return (
    <Root>
      <Label>
        {props.label}
        {props.required && ' *'}
      </Label>
      <InputContent {...props} name={props.name} />
    </Root>
  );
};

Input.propTypes = {
  label: PropTypes.string,
};

const Root = styled.div`
  text-align: left;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: ${vw(1)};
    min-height: 1px;
    opacity: 0.68;
    background-color: #ffffff;
  }
  @media (min-width: ${media.tablet}) {
    &::before {
      content: '';
      height: ${vwTablet(1)};
    }
  }
  @media (min-width: ${media.desktop}) {
    &::before {
      content: '';
      height: ${vwDesktop(1)};
    }
  }
`;

const Label = styled.label`
  opacity: 0.64;
  font-size: ${vw(12)};
  letter-spacing: ${vw(2)};
  color: #ffffff;
  text-transform: uppercase;
  display: block;
  margin-bottom: ${vw(10)};
  @media (min-width: ${media.tablet}) {
    font-size: ${vwTablet(14)};
    letter-spacing: ${vwTablet(2)};
    margin-bottom: ${vwTablet(8)};
  }
  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(14)};
    letter-spacing: ${vwDesktop(2)};
    margin-bottom: ${vwDesktop(8)};
  }
`;

const InputContent = styled.input`
  width: 100%;
  font-size: ${vw(14)};
  line-height: ${vw(20)};
  letter-spacing: ${vw(1)};
  color: #ffffff;
  background: none;
  border: none;
  outline: none;
  appearance: none;
  padding-bottom: ${vw(5)};
  border-bottom: ${vw(2)} solid #05837c;
  transition: all 0.25s ease-out;
  &:focus {
    border-bottom: ${vw(2)} solid #ffffff;
  }
  &:-webkit-autofill {
    -webkit-text-fill-color: #ffffff;
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  @media (min-width: ${media.tablet}) {
    font-size: ${vwTablet(17)};
    line-height: ${vwTablet(28)};
    letter-spacing: ${vwTablet(1)};
    padding-bottom: ${vwTablet(5)};
    border-bottom: ${vwTablet(2)} solid #05837c;
    &:focus {
      border-bottom: ${vwTablet(2)} solid #ffffff;
    }
  }
  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(18)};
    line-height: ${vwDesktop(28)};
    letter-spacing: ${vwDesktop(1)};
    padding-bottom: ${vwDesktop(5)};
    border-bottom: ${vwDesktop(2)} solid #05837c;
    &:focus {
      border-bottom: ${vwDesktop(2)} solid #ffffff;
    }
  }
`;

export default Input;
