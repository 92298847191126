import React, { Component } from 'react';
import Intl from 'src/components/Intl';

import Hero from './sections/Hero';
import Contact from './sections/Contact';

class Home extends Component {
  render() {
    return (
      <Intl>
        {(t, { locale }) => {
          return (
            <main>
              <Hero />
              <Contact />
            </main>
          );
        }}
      </Intl>
    );
  }
}

export default Home;
