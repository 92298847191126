import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Container from 'components/Container';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

const Footer = () => {
  return (
    <Root>
      <StyledContainer>
        <Logo
          src={require('src/assets/images/footer-icon.png')}
          alt="Prim Properties logo"
        />
        <Item>
          <h1>PRIM Properties</h1>
          <a
            href="https://www.google.com/maps?q=335-8120+Granville+Ave.+Richmond,+BC+V6Y+1P3&rlz=1C5CHFA_enCA842CA842&um=1&ie=UTF-8&sa=X&ved=0ahUKEwjrnvLTyvbhAhWLq54KHaJPBiEQ_AUIDigB"
            target="_blank"
            rel="noopener noreferrer"
          >
            335-8120 Granville Ave.
            <br />
            Richmond, BC
            <br />
            V6Y 1P3
          </a>
        </Item>
        <Item>
          <h1>Contact Us</h1>
          <a href="tel:604-288-4988" target="_blank" rel="noopener noreferrer">
            604-288-4988
          </a>
          <a
            href="mailto:contact@primproperties.ca"
            target="_blank"
            rel="noopener noreferrer"
          >
            contact@primproperties.ca
          </a>
        </Item>
      </StyledContainer>
      <Disclaimer>
        © 2019 Prim Properties.
        <br />
        All Rights Reserved. <br />
        <TermsAndPrivacyButton to="/terms_privacy">
          TERMS OF USE &amp; PRIVACY POLICY
        </TermsAndPrivacyButton>
      </Disclaimer>
    </Root>
  );
};

const Root = styled.div`
  text-align: center;
  padding-top: ${vw(40)};
  padding-bottom: ${vw(22)};
  @media (min-width: ${media.tablet}) {
  }
  @media (min-width: ${media.desktop}) {
    padding-top: ${vwDesktop(80)};
    padding-bottom: ${vwDesktop(20)};
  }
`;

const StyledContainer = styled(Container)`
  @media (min-width: ${media.tablet}) {
    display: flex;
  }
  @media (min-width: ${media.desktop}) {
  }
`;

const Logo = styled.img`
  width: ${vw(50)};
  height: ${vw(50)};
  @media (min-width: ${media.tablet}) {
    margin-right: 13.28%;
    width: ${vwTablet(80)};
    height: ${vwTablet(80)};
  }
  @media (min-width: ${media.desktop}) {
    margin-right: 34%;
    width: ${vwDesktop(100)};
    height: ${vwDesktop(100)};
  }
`;

const Item = styled.div`
  width: 66.67%;
  margin: 0 auto;
  text-align: center;
  h1 {
    font-size: ${vw(14)};
    font-weight: 600;
    line-height: ${vw(16)};
    letter-spacing: ${vw(1.8)};
    text-align: center;
    text-transform: uppercase;
    color: black;
    margin-top: ${vw(40)};
    margin-bottom: ${vw(10)};
  }
  a {
    font-size: ${vw(14)};
    line-height: ${vw(24)};
    letter-spacing: ${vw(0.5)};
    text-align: center;
    color: black;
    display: block;
    cursor: pointer;
  }
  @media (min-width: ${media.tablet}) {
    h1 {
      font-size: ${vwTablet(16)};
      line-height: ${vwTablet(16)};
      letter-spacing: ${vwTablet(2)};
      margin-top: 0;
      margin-bottom: ${vwTablet(10)};
      text-align: left;
    }
    a {
      font-size: ${vwTablet(16)};
      line-height: ${vwTablet(28)};
      letter-spacing: ${vwTablet(1)};
      text-align: left;
    }
  }
  @media (min-width: ${media.desktop}) {
    h1 {
      font-size: ${vwDesktop(16)};
      line-height: ${vwDesktop(16)};
      letter-spacing: ${vwDesktop(2)};
      margin-bottom: ${vwDesktop(20)};
    }
    a {
      font-size: ${vwDesktop(16)};
      line-height: ${vwDesktop(28)};
      letter-spacing: ${vwDesktop(1)};
    }
  }
`;

const Disclaimer = styled.p`
  font-size: ${vw(10)};
  line-height: ${vw(15)};
  letter-spacing: ${vw(0.8)};
  text-align: center;
  color: #636466;
  width: 66.67%;
  margin: 0 auto;
  margin-top: ${vw(60)};
  text-transform: uppercase;
  @media (min-width: ${media.tablet}) {
    font-size: ${vwTablet(10)};
    line-height: ${vwTablet(30)};
    letter-spacing: ${vwTablet(0.83)};
    margin-top: ${vwTablet(60)};
    text-align: left;
    margin-left: 26%;
    br {
      display: none;
    }
  }
  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(12)};
    line-height: ${vwDesktop(30)};
    letter-spacing: ${vwDesktop(1)};
    margin-top: ${vwDesktop(80)};
    text-align: left;
    margin-left: 42.5%;
  }
`;

const TermsAndPrivacyButton = styled(Link)`
  font-size: ${vw(10)};
  line-height: ${vw(15)};
  letter-spacing: ${vw(0.8)};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  color: #636466;
  text-decoration: underline;
  @media (min-width: ${media.tablet}) {
    font-size: ${vwTablet(10)};
    line-height: ${vwTablet(30)};
    letter-spacing: ${vwTablet(0.83)};
  }
  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(12)};
    line-height: ${vwDesktop(30)};
    letter-spacing: ${vwDesktop(1)};
  }
`;

export default Footer;
