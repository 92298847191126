import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

const Container = props => {
  return <Root {...props}>{props.children}</Root>;
};

Container.propTypes = {
  children: PropTypes.any,
};

const Root = styled.div`
  padding: 0 ${vw(12.5)};
  @media (min-width: ${media.tablet}) {
    padding: 0 ${vwTablet(30)};
  }
  @media (min-width: ${media.desktop}) {
    padding: 0 ${vwDesktop(80)};
  }
`;

export default Container;
