import React from 'react';
import styled from 'styled-components';

import logo from 'src/assets/images/prim_logo.svg';
import logoMobile from 'src/assets/images/prim_logo_mobile.svg';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

export default () => {
  return (
    <Root>
      <Logo src={logo} alt="Prim Properties logo" />
      <LogoMobile src={logoMobile} alt="Prim Properties mobile logo" />
    </Root>
  );
};

const Root = styled.div``;

const Logo = styled.img`
  z-index: 2;
  position: relative;
  display: none;
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(103)};
    display: block;
  }
  @media (min-width: ${media.desktop}) {
    position: absolute;
    top: ${vwDesktop(60)};
    left: ${vwDesktop(80)};
    height: ${vwDesktop(134)};
  }
`;

const LogoMobile = styled.img`
  height: ${vw(52)};
  z-index: 2;
  position: relative;
  display: block;
  @media (min-width: ${media.tablet}) {
    display: none;
  }
  @media (min-width: ${media.desktop}) {
  }
`;
