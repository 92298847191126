import React from 'react';
import styled from 'styled-components';

import Container from 'components/Container';
import Logo from 'components/Logo';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

const hanldeClick = () => {
  const scrollToElement = require('scroll-to-element');
  scrollToElement('#section-contact', {
    offset: 0,
    duration: 700,
  });
};

const Hero = () => {
  return (
    <Root>
      <Container>
        <Logo />
      </Container>
      <Banner>
        <img
          src={require('src/assets/images/p_shape.jpg')}
          alt="P shaped logo with couple in background"
        />
        <Container>
          <Content>
            <h1>
              Building
              <br /> Together
            </h1>
            <p>
              As a community-focused homebuilder, we believe that every new home
              is a new opportunity, a landmark in your life that is the starting
              point to building a brighter future.
            </p>
          </Content>
          <ContactWrapper onClick={hanldeClick}>
            <h2>Contact Us</h2>
            <img src={require('src/assets/images/arrow.svg')} alt="arrow" />
          </ContactWrapper>
        </Container>
      </Banner>
    </Root>
  );
};

const Root = styled.div`
  text-align: left;
  padding-top: ${vw(21)};
  padding-bottom: ${vw(40)};
  position: relative;
  width: 100%;
  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(30)};
    padding-bottom: ${vwTablet(316)};
  }
  @media (min-width: ${media.desktop}) {
    padding-top: 0;
    padding-bottom: ${vwDesktop(458)};
  }
`;

const Banner = styled.div`
  position: relative;
  margin-top: ${vw(17)};
  > img {
    width: auto;
    height: 80%;
    position: absolute;
    right: ${vw(15)};
  }
  @media (min-width: ${media.tablet}) {
    margin-top: ${vwTablet(-20)};
    > img {
      width: 180%;
      height: auto;
      position: absolute;
      right: -10%;
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-top: 0;
    > img {
      width: 114%;
      height: auto;
      position: absolute;
      top: ${vwDesktop(-80)};
      right: ${vwDesktop(80)};
    }
  }
`;

const Content = styled.div`
  position: relative;
  margin-left: 25%;
  h1 {
    font-size: ${vw(35)};
    font-weight: 600;
    letter-spacing: ${vw(1)};
    color: #ffffff;
    text-transform: uppercase;
    padding-top: ${vw(116)};
    padding-bottom: ${vw(153)};
  }
  p {
    font-size: ${vw(14)};
    line-height: ${vw(20)};
    letter-spacing: ${vw(1)};
    color: #ffffff;
  }
  @media (min-width: ${media.tablet}) {
    margin-left: 0;
    h1 {
      font-size: ${vwTablet(58)};
      letter-spacing: ${vwTablet(6)};
      text-align: center;
      padding-top: ${vwTablet(316)};
      padding-bottom: ${vwTablet(40)};
    }
    br {
      display: none;
    }
    p {
      font-size: ${vwTablet(17)};
      line-height: ${vwTablet(28)};
      letter-spacing: ${vwTablet(1)};
      width: 58.33%;
      margin: 0 auto;
      margin-left: 25%;
    }
  }
  @media (min-width: ${media.desktop}) {
    h1 {
      font-size: ${vwDesktop(70)};
      letter-spacing: ${vwDesktop(6)};
      padding-top: ${vwDesktop(334)};
      padding-bottom: ${vwDesktop(20)};
    }
    p {
      width: 33.33%;
      font-size: ${vwDesktop(18)};
      line-height: ${vwDesktop(28)};
      letter-spacing: ${vwDesktop(1)};
      margin-left: 41.67%;
    }
  }
`;

const ContactWrapper = styled.div`
  margin-left: 25%;
  margin-top: ${vw(40)};
  z-index: 1;
  position: relative;
  cursor: pointer;
  h2 {
    font-size: ${vw(16)};
    font-weight: 600;
    letter-spacing: ${vw(1.2)};
    color: ${({ theme }) => theme.color.iceBlue};
    text-transform: uppercase;
    margin-bottom: ${vw(18)};
  }
  @media (min-width: ${media.tablet}) {
    margin-top: ${vwTablet(34)};
    h2 {
      font-size: ${vwTablet(20)};
      letter-spacing: ${vwTablet(1.5)};
      margin-bottom: ${vwTablet(30)};
    }
  }
  @media (min-width: ${media.desktop}) {
    margin-top: ${vwDesktop(34)};
    margin-left: 41.67%;
    h2 {
      font-size: ${vwDesktop(20)};
      letter-spacing: ${vwDesktop(1.5)};
      margin-bottom: ${vwDesktop(30)};
    }
  }
`;

export default Hero;
