import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

const Button = props => {
  return (
    <Root {...props}>
      <Text>{props.text}</Text>
    </Root>
  );
};

Button.propTypes = {
  text: PropTypes.string,
};

const Root = styled.button`
  height: ${vw(60)};
  /* width: 100%; */
  width: ${vw(202)};
  border-radius: ${vw(13)};
  border: solid ${vw(3)} #ffffff;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 85%;
    width: ${vw(19)};
    height: ${vw(28)};
    background: url(${require('src/assets/images/arrow.svg')});
    background-repeat: no-repeat;
    background-size: cover;
    transform: translate3d(-50%, -50%, 0) rotate(270deg);
  }
  @media (min-width: ${media.tablet}) {
    width: 40%;
    height: ${vwTablet(60)};
    border-radius: ${vwTablet(13)};
    border: solid ${vwTablet(3)} #ffffff;
    &::before {
      content: '';
      width: ${vwTablet(19)};
      height: ${vwTablet(28)};
      left: 85%;
    }
  }
  @media (min-width: ${media.desktop}) {
    width: 40%;
    height: ${vwDesktop(60)};
    border-radius: ${vwDesktop(13)};
    border: solid ${vwDesktop(3)} #ffffff;
    &::before {
      content: '';
      width: ${vwDesktop(19)};
      height: ${vwDesktop(28)};
      left: 85%;
    }
  }
`;

const Text = styled.p`
  font-size: ${vw(16)};
  font-weight: 600;
  letter-spacing: ${vw(1.2)};
  color: ${({ theme }) => theme.color.iceBlue};
  padding-left: ${vw(20)};
  text-transform: uppercase;
  @media (min-width: ${media.tablet}) {
    font-size: ${vwTablet(20)};
    letter-spacing: ${vwTablet(1.5)};
    padding-left: ${vwTablet(20)};
  }
  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(20)};
    letter-spacing: ${vwDesktop(1.5)};
    padding-left: ${vwDesktop(20)};
  }
`;

export default Button;
