import React, { useState } from 'react';
import styled from 'styled-components';

import Input from './elements/Input';
import Textarea from './elements/Textarea';
import Button from 'components/Button';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

const Form = props => {
  const [isMailSent, setIsMailSent] = useState(false);

  function handleMailerSent() {
    const iframe = document.getElementById('frame_submission');
    setIsMailSent(true);
  }

  function handleBackToForm() {
    setIsMailSent(false);
    document.getElementById('form').reset();
  }

  return (
    <Root>
      <iframe name="mailerIframe" src="" title="form" id="frame_submission" />
      <FormWrapper
        {...props}
        method="post"
        action="https://i.bamcommunications.ca/mailer/prim_mailer-v2.php"
        target="mailerIframe"
        show={!isMailSent}
        onSubmit={handleMailerSent}
        id="form"
      >
        <h1>Contact Us</h1>
        <h2>Please fill out the form and we will be in touch soon.</h2>
        <QuestionWrapper>
          <input
            type="hidden"
            name="mailto"
            value="contact@primproperties.ca"
          />
          <Input label="Name" required name="name" />
          <Input type="email" label="Email" required name="email" />
          <Input
            label="Phone Number"
            // required
            name="tel"
            pattern="[0-9]{3}?(-|.|'')?[0-9]{3}?(-|.|'')?[0-9]{4}"
          />
          <Textarea label="Message *" required name="message" />
        </QuestionWrapper>
        {/* <Button text="Submit" onClick={handleMailerSent} /> */}
        <Button text="Submit" />
      </FormWrapper>
      <ThankyouWrapper show={isMailSent}>
        <h2>
          Thank you
          <br />
          for getting in touch.
        </h2>
        <p>
          We appreciate that you’ve taken the time to write us. We’ll get back
          to you very soon.{' '}
        </p>
        <CloseButton onClick={handleBackToForm}>Close</CloseButton>
      </ThankyouWrapper>
    </Root>
  );
};

const Root = styled.div`
  border-radius: ${vw(10)};
  background-color: ${({ theme }) => theme.color.deepAqua};
  padding-left: ${vw(25)};
  padding-right: ${vw(25)};
  padding-top: ${vw(31)};
  padding-bottom: ${vw(40)};
  position: relative;
  @media (min-width: ${media.tablet}) {
    border-radius: ${vwTablet(10)};
    padding-left: ${vwTablet(60)};
    padding-right: ${vwTablet(70)};
    padding-top: ${vwTablet(65)};
    padding-bottom: ${vwTablet(60)};
  }
  @media (min-width: ${media.desktop}) {
    border-radius: ${vwDesktop(10)};
    padding-left: ${vwDesktop(60)};
    padding-right: ${vwDesktop(60)};
    padding-top: ${vwDesktop(60)};
    padding-bottom: ${vwDesktop(60)};
  }
  iframe {
    display: none;
  }
`;

const FormWrapper = styled.form`
  text-align: left;
  opacity: ${props => (props.show ? 1 : 0)};
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  transition: opacity 0.4s ease-out;
  h1 {
    font-size: ${vw(30)};
    font-weight: 600;
    letter-spacing: ${vw(1.5)};
    color: #ffffff;
    margin-bottom: ${vw(5)};
  }
  h2 {
    font-size: ${vw(14)};
    line-height: ${vw(20)};
    letter-spacing: ${vw(1)};
    color: #ffffff;
    margin-bottom: ${vw(40)};
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
  }
  @media (min-width: ${media.tablet}) {
    h1 {
      font-size: ${vwTablet(40)};
      letter-spacing: ${vwTablet(2)};
      margin-bottom: ${vwTablet(9)};
    }
    h2 {
      font-size: ${vwTablet(17)};
      line-height: ${vwTablet(28)};
      letter-spacing: ${vwTablet(1)};
      margin-bottom: ${vwTablet(83)};
    }
  }
  @media (min-width: ${media.desktop}) {
    h1 {
      font-size: ${vwDesktop(45)};
      letter-spacing: ${vwDesktop(2)};
      margin-bottom: ${vwDesktop(10)};
    }
    h2 {
      font-size: ${vwDesktop(18)};
      line-height: ${vwDesktop(28)};
      letter-spacing: ${vwDesktop(1)};
      margin-bottom: ${vwDesktop(80)};
    }
  }
`;

const QuestionWrapper = styled.div`
  > div {
    margin-bottom: ${vw(40)};
  }
  @media (min-width: ${media.tablet}) {
    > div {
      margin-bottom: ${vwTablet(40)};
    }
  }
  @media (min-width: ${media.desktop}) {
    > div {
      margin-bottom: ${vwDesktop(40)};
    }
  }
`;

const ThankyouWrapper = styled.div`
  text-align: left;
  position: absolute;
  padding-left: ${vw(15)};
  padding-right: ${vw(15)};
  top: 50%;
  left: 0;
  transform: translate3d(0, -50%, 0);
  opacity: ${props => (props.show ? 1 : 0)};
  pointer-events: ${props => (props.show ? 'auto' : 'none')};
  transition: opacity 0.4s ease-out;
  h2 {
    font-size: ${vw(45)};
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: ${vw(2)};
    color: #ffffff;
    margin-bottom: ${vw(40)};
  }
  p {
    font-size: ${vw(18)};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: ${vw(28)};
    letter-spacing: ${vw(1)};
    color: #ffffff;
    margin-bottom: ${vw(154)};
  }
  @media (min-width: ${media.tablet}) {
    padding-left: ${vwTablet(25)};
    padding-right: ${vwTablet(25)};
    h2 {
      font-size: ${vwTablet(28)};
      letter-spacing: ${vwTablet(2)};
      margin-bottom: ${vwTablet(40)};
    }
    p {
      font-size: ${vwTablet(18)};
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: ${vwTablet(28)};
      letter-spacing: ${vwTablet(1)};
      color: #ffffff;
      margin-bottom: ${vwTablet(154)};
    }
  }
  @media (min-width: ${media.desktop}) {
    padding-left: ${vwDesktop(60)};
    padding-right: ${vwDesktop(60)};
    h2 {
      font-size: ${vwDesktop(22)};
      letter-spacing: ${vwDesktop(2)};
      margin-bottom: ${vwDesktop(40)};
    }
    p {
      font-size: ${vwDesktop(18)};
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: ${vwDesktop(28)};
      letter-spacing: ${vwDesktop(1)};
      color: #ffffff;
      margin-bottom: ${vwDesktop(154)};
    }
  }
`;

const CloseButton = styled.div`
  font-size: ${vw(20)};
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  text-transform: uppercase;
  letter-spacing: ${vw(1.5)};
  color: ${({ theme }) => theme.color.iceBlue};
  cursor: pointer;
  @media (min-width: ${media.tablet}) {
    font-size: ${vwTablet(20)};
    letter-spacing: ${vwTablet(1.5)};
  }
  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(20)};
    letter-spacing: ${vwDesktop(1.5)};
  }
`;

export default Form;
