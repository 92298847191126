import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

const Textarea = props => {
  return (
    <Root>
      <Label>{props.label}</Label>
      <TextareaContent {...props} name={props.name} />
      {props.required && <span>* Required</span>}
    </Root>
  );
};

Textarea.propTypes = {
  label: PropTypes.string,
};

const Root = styled.div`
  span {
    opacity: 0.64;
    font-size: ${vw(12)};
    letter-spacing: ${vw(1.7)};
    color: #ffffff;
    text-transform: uppercase;
  }
  @media (min-width: ${media.tablet}) {
    span {
      font-size: ${vwTablet(14)};
      letter-spacing: ${vwTablet(2)};
    }
  }
  @media (min-width: ${media.desktop}) {
    span {
      font-size: ${vwDesktop(14)};
      letter-spacing: ${vwDesktop(2)};
    }
  }
`;

const Label = styled.div`
  opacity: 0.64;
  font-size: ${vw(12)};
  letter-spacing: ${vw(2)};
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: ${vw(10)};
  @media (min-width: ${media.tablet}) {
    font-size: ${vwTablet(14)};
    letter-spacing: ${vwTablet(2)};
    margin-bottom: ${vwTablet(38)};
  }
  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(14)};
    letter-spacing: ${vwDesktop(2)};
    margin-bottom: ${vwDesktop(38)};
  }
`;

const TextareaContent = styled.textarea`
  width: 100%;
  height: ${vw(130)};
  display: block;
  margin-bottom: ${vw(10)};
  background: none;
  outline: none;
  border-radius: ${vw(5)};
  border: solid ${vw(1)} rgba(255, 255, 255, 0.68);
  font-size: ${vw(14)};
  line-height: ${vw(20)};
  letter-spacing: ${vw(1)};
  color: #ffffff;
  padding: ${vw(5)};
  transition: all 0.1s ease-out;
  &:focus {
    border: ${vw(2)} solid #ffffff;
  }
  @media (min-width: ${media.tablet}) {
    height: ${vwTablet(130)};
    margin-bottom: ${vwTablet(10)};
    border-radius: ${vwTablet(5)};
    border: solid ${vwTablet(1)} rgba(255, 255, 255, 0.68);
    font-size: ${vwTablet(14)};
    line-height: ${vwTablet(20)};
    letter-spacing: ${vwTablet(2)};
    padding: ${vwTablet(5)};
    &:focus {
      border: ${vwTablet(2)} solid #ffffff;
    }
  }
  @media (min-width: ${media.desktop}) {
    height: ${vwDesktop(130)};
    margin-bottom: ${vwDesktop(10)};
    border-radius: ${vwDesktop(10)};
    border: solid ${vwDesktop(1)} rgba(255, 255, 255, 0.68);
    font-size: ${vwDesktop(18)};
    line-height: ${vwDesktop(28)};
    letter-spacing: ${vwDesktop(1)};
    padding: ${vwDesktop(5)};
    &:focus {
      border: ${vwDesktop(2)} solid #ffffff;
    }
  }
`;

export default Textarea;
