import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Container from 'components/Container';

import media from 'src/styles/media';
import { vw, vwTablet, vwDesktop } from 'src/styles/utils';

const TermsAndPrivacy = () => {
  return (
    <Root>
      <Container>
        <Head>
          <Link to="/">
            <img
              src={require('src/assets/images/prim_logo_colour.svg')}
              alt="Prim Properties wordmark"
            />
          </Link>
          <h2>
            <BackButton to="/">Back</BackButton>
          </h2>
        </Head>
      </Container>
      <ContentWrapper>
        <StyledContainer>
          <h1>TERMS OF USE &amp; PRIVACY POLICY</h1>
          <h2>TERMS OF USE</h2>
          <p>
            The material, images, renderings and information on this website is
            provided on an ‘as is’ basis solely for the reader’s general
            knowledge. The information is not intended to be a comprehensive
            review of all matters and developments concerning Prim Properties,
            and therefore assumes no responsibility and provides no warranty,
            either express or implied, as to its accuracy, completeness or
            currency. In no event will Prim Properties be liable for any
            special, incidental, indirect or consequential damages of any kind,
            or any damages whatsoever arising out of or in connection with the
            use or performance of this information, including without
            limitation, those resulting from loss of use, data or profits,
            whether or not advised of the possibility of damage. This site may
            contain links to external organizations. We do not control the
            relevance, timeliness, or accuracy of materials produced by other
            organizations, nor do we endorse their views, products or services.
            Your access and use of this website constitutes your agreement to be
            governed by the contents of the disclaimer set forth above.
          </p>
          <h2>PRIVACY POLICY</h2>
          <p>
            Prim Properties and each of our affiliates and partnerships is
            committed to maintaining the accuracy, confidentiality and security
            of your personal information. This Privacy Policy describes the
            personal information that Prim Properties collects from or about
            you, how we use that information, and to whom we disclose that
            information.
          </p>
          <h2>COLLECTING PERSONAL INFORMATION</h2>
          <p>
            Prim Properties collects personal information to better serve your
            needs, whether purchasing products and services from us or obtaining
            service or warranty work. In order to provide you with our wide
            variety of services, products and promotions, we may collect the
            following information:
          </p>
          <ul>
            <li>
              Contact and mailing information such as name, address, telephone
              numbers, fax numbers and email addresses.
            </li>
            <li>
              The plans, specifications and other particulars about the home
              that is being built for you.
            </li>
            <li>Municipal and legal descriptions for your home.</li>
            <li>
              Any other relevant information necessary to provide you with our
              products and services.
            </li>
            <li>
              We may need to collect other personal information depending on the
              circumstance, but we will ask you directly and ensure that you
              agree.
            </li>
          </ul>
          <h2>PERSONAL INFORMATION SECURITY</h2>
          <p>
            Like you, Prim Properties values our personal information. Part of
            valuing your personal information is making sure that it is
            protected and kept confidential. We achieve this by:
          </p>
          <ul>
            <li>Having our employees sign a confidentiality agreement.</li>
            <li>
              Having up to date computer security such as passwords, encryption
              and firewalls.
            </li>
            <li>
              Where third parties are hired to assist us with providing products
              and services to you for your home, we have them sign a privacy and
              confidentiality agreement to ensure that your personal information
              is protected by them.
            </li>
          </ul>
          <h2>PERSONAL INFORMATION UPDATING</h2>
          <p>
            Prim Properties wants to ensure that your personal information is
            always correct up to date. If your personal information changes or
            is incorrect, updates can be made by contacting your representative
            with the change and any other information you feel is necessary.
            <br />
            <br />
            We will keep your information as long as is necessary to continue to
            meet your customer needs and any legal requirements we may have.
            However, once your personal information no longer needed, we will
            destroy it.
          </p>
          <h2>PERSONAL DATA</h2>
          <p>
            We only collect Personal Data from you, such as first and last name,
            phone number, e-mail, when you register to receive information. We
            collect data about your activities that does not personally or
            directly identify you when you visit our website, or the websites
            and online services where we display advertisements. This
            information may include the content you view, the date and time that
            you view this content, the products you purchase, or your location
            information associated with your IP address. We use the information
            we collect to serve you more relevant advertisements (referred to as
            “Retargeting”). We collect information about where you saw the ads
            we serve you and what ads you clicked on.
            <br />
            <br />
            As you browse the website, online ad networks we work with may place
            anonymous cookies on your computer, and use similar technologies, in
            order to understand your interests based on your (anonymous) online
            activities, and thus to tailor more relevant ads to you. If you do
            not wish to receive such tailored advertising, you can visit this
            page to opt out of most companies that engage in such advertising.
            (This will not prevent you from seeing ads; the ads simply will not
            be delivered through these targeting methods.)
            <br />
            <br />
            We do not target ads to you based on sensitive personal data, such
            as information related to race or ethnic origins, political
            opinions, religious beliefs or other beliefs of a similar nature,
            trade union membership, physical or mental health or condition or
            sexual life. We may target ads based on data provided by Advertisers
            or partners alone or in combination with the data we collect
            ourselves. Any data used to serve targeted advertisements is
            de-identified and is not used to personally or directly identify
            you. In order to allow us to reach the best inventory online, we
            work with third-party advertising companies (our “Advertising
            Partners”) to help us recognize you and serve relevant
            advertisements to you when you visit a website or online service in
            their network. We also work with Advertising Partners who help us
            recognize you across different devices in order to show you relevant
            advertisements. Our Advertising Partners may collect information
            about your activities on our website, and other websites or online
            services in their networks. We also work with third party companies
            to assist us with website analytics such as evaluating the use and
            operation of our website so that we can continue to enhance the
            website and our services.
            <br />
            <br />
            We and our third-party partners use session cookies and persistent
            cookies to make it easier for you to navigate and enhance the
            experience of our site, and to monitor and evaluate our website’s
            operation and use.
            <br />
            <br />
            To modify or delete your personal information, or to opt out of
            future communications, please contact us at:
            <br />
            <br />
            <strong>PRIM PROPERTIES</strong>
            <br />
            <Address
              href="https://www.google.com/maps/search/335-8120+Granville+Ave.+Richmond,+BC++V6Y+1P3/@49.1623463,-123.136522,17z/data=!3m1!4b1"
              target="_blank"
              rel="noopener noreferrer"
            >
              335-8120 Granville Ave.
              <br />
              Richmond, BC
              <br />
              V6Y 1P3
            </Address>
            <br />
            <br />
            Email:{' '}
            <Email
              href="mailto:privacy@Primproperties.ca"
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy@Primproperties.ca
            </Email>
          </p>
          <h2>
            <BackToHomeButton to="/">Back to Homepage</BackToHomeButton>
          </h2>
        </StyledContainer>
      </ContentWrapper>
    </Root>
  );
};

const Root = styled.div``;

const Head = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: ${vw(60)};
  padding-bottom: ${vw(87)};
  overflow-x: hidden;
  img {
    width: ${vw(90)};
    height: ${vw(67)};
  }

  @media (min-width: ${media.tablet}) {
    padding-top: ${vwTablet(60)};
    padding-bottom: ${vwTablet(87)};
    img {
      width: ${vwTablet(180)};
      height: ${vwTablet(134)};
    }
  }
  @media (min-width: ${media.desktop}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: ${vwDesktop(60)};
    padding-bottom: ${vwDesktop(87)};
    img {
      width: ${vwDesktop(180)};
      height: ${vwDesktop(134)};
    }
  }
`;

const BackButton = styled(Link)`
  font-size: ${vw(20)};
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: ${vw(1.5)};
  color: ${({ theme }) => theme.color.teal};
  text-transform: uppercase;
  display: none;
  @media (min-width: ${media.tablet}) {
    font-size: ${vwTablet(20)};
    letter-spacing: ${vwTablet(1.5)};
  }
  @media (min-width: ${media.desktop}) {
    display: block;
    font-size: ${vwDesktop(20)};
    letter-spacing: ${vwDesktop(1.5)};
  }
`;

const ContentWrapper = styled.div`
  padding: 0 ${vw(12.5)};
  padding-top: ${vw(53)};
  padding-bottom: ${vw(120)};
  background: ${({ theme }) => theme.color.iceBlue};
  width: 100%;
  overflow-x: hidden;
  @media (min-width: ${media.tablet}) {
    padding: 0 ${vwTablet(30)};
    padding-top: ${vwTablet(53)};
    padding-bottom: ${vwTablet(120)};
  }
  @media (min-width: ${media.desktop}) {
    padding: 0 ${vwDesktop(80)};
    padding-top: ${vwDesktop(53)};
    padding-bottom: ${vwDesktop(120)};
  }
`;

const StyledContainer = styled(Container)`
  width: 100%;
  margin: 0 auto;
  h1 {
    font-size: ${vw(28)};
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: ${vw(1)};
    color: ${({ theme }) => theme.color.teal};
  }
  h2 {
    font-size: ${vw(20)};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: ${vw(50)};
    letter-spacing: ${vw(0.9)};
    color: black;
    margin-top: ${vw(80)};
    margin-bottom: ${vw(10)};
  }
  p {
    font-size: ${vw(16)};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: ${vw(28)};
    letter-spacing: ${vw(0.5)};
    color: black;
  }

  ul {
    margin-top: ${vw(24)};
  }

  li {
    font-size: ${vw(16)};
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: ${vw(28)};
    letter-spacing: ${vw(0.5)};
    color: black;
    margin-left: ${vw(24)};
  }

  strong {
    font-weight: 600;
  }
  @media (min-width: ${media.tablet}) {
    h1 {
      font-size: ${vwTablet(45)};
      letter-spacing: ${vwTablet(2)};
    }
    h2 {
      font-size: ${vwTablet(28)};
      line-height: ${vwTablet(50)};
      letter-spacing: ${vwTablet(1.87)};
      margin-top: ${vwTablet(80)};
      margin-bottom: ${vwTablet(10)};
    }
    p {
      font-size: ${vwTablet(16)};
      line-height: ${vwTablet(28)};
      letter-spacing: ${vwTablet(0.5)};
    }
    ul {
      margin-top: ${vwTablet(24)};
    }
    li {
      font-size: ${vwTablet(16)};
      line-height: ${vwTablet(28)};
      letter-spacing: ${vwTablet(0.5)};
      margin-left: ${vwTablet(24)};
    }
  }
  @media (min-width: ${media.desktop}) {
    width: 66.67%;
    h1 {
      font-size: ${vwDesktop(45)};
      letter-spacing: ${vwDesktop(2)};
    }
    h2 {
      font-size: ${vwDesktop(28)};
      line-height: ${vwDesktop(50)};
      letter-spacing: ${vwDesktop(1.87)};
      margin-top: ${vwDesktop(80)};
      margin-bottom: ${vwDesktop(10)};
    }
    p {
      font-size: ${vwDesktop(16)};
      line-height: ${vwDesktop(28)};
      letter-spacing: ${vwDesktop(0.5)};
    }
    ul {
      margin-top: ${vwDesktop(24)};
    }
    li {
      font-size: ${vwDesktop(16)};
      line-height: ${vwDesktop(28)};
      letter-spacing: ${vwDesktop(0.5)};
      margin-left: ${vwDesktop(24)};
    }
  }
`;

const Address = styled.a`
  font-size: ${vw(16)};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: ${vw(28)};
  letter-spacing: ${vw(0.5)};
  color: black;
  @media (min-width: ${media.tablet}) {
    font-size: ${vwTablet(16)};
    line-height: ${vwTablet(28)};
    letter-spacing: ${vwTablet(0.5)};
  }
  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(16)};
    line-height: ${vwDesktop(28)};
    letter-spacing: ${vwDesktop(0.5)};
  }
`;

const Email = styled.a`
  font-size: ${vw(16)};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: ${vw(28)};
  letter-spacing: ${vw(0.5)};
  color: black;
  text-decoration: underline;
  @media (min-width: ${media.tablet}) {
    font-size: ${vwTablet(16)};
    line-height: ${vwTablet(28)};
    letter-spacing: ${vwTablet(0.5)};
  }
  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(16)};
    line-height: ${vwDesktop(28)};
    letter-spacing: ${vwDesktop(0.5)};
  }
`;

const BackToHomeButton = styled(Link)`
  font-size: ${vw(20)};
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: ${vw(1.5)};
  color: ${({ theme }) => theme.color.teal};
  text-transform: uppercase;
  @media (min-width: ${media.tablet}) {
    font-size: ${vwTablet(20)};
    letter-spacing: ${vwTablet(1.5)};
  }
  @media (min-width: ${media.desktop}) {
    font-size: ${vwDesktop(20)};
    letter-spacing: ${vwDesktop(1.5)};
  }
`;

export default TermsAndPrivacy;
