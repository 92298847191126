import { createGlobalStyle } from 'styled-components';
import theme from './theme';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  #app {
    width: 100%;
    height: 100%;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 0;
  }

  #app {
    > div {
      overflow-x: hidden;
    }
  }
  
  body {
    font-family: 'Adrianna';
    font-style: normal;
    font-weight: normal;
    font-stretch: normal;
    overflow-x: hidden;
  }

  a,
  button {
    outline: none;
    border: none;
    background: none;
    text-decoration: none;
    color: ${theme.color.primary};
  }

  main {
    text-align: center;
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #009189;
    overflow-x: hidden;
  }

  @font-face {
    font-family: 'Adrianna';
    src: url('/assets/fonts/adrianna.woff2') format('woff2'),
      url('/assets/fonts/adrianna.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Adrianna';
    src: url('/assets/fonts/adrianna-bold.woff2') format('woff2'),
      url('/assets/fonts/adrianna-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
  }
  
`;
